<script setup lang="ts">
import { add, sub } from 'date-fns';
import AppCalendar from '~/components/app/AppCalendar.vue';
import LineChart from '~/components/chart/LineChart.vue';

defineProps({
  short: {
    type: Boolean,
    default: false,
  },
});

const spellId = useRouteParams('spellId');

const dateRange = ref({
  start: sub(new Date(), { days: 14 }),
  end: add(new Date(), { days: 1 }),
});

const { data: insights, pending } = await useLazyApi(
  `/api/spells/${spellId.value}/insights`,
  {
    method: 'POST',
    body: dateRange,
  },
);

const dates = computed(
  () => insights.value?.map((item) => item.date_iso) || [],
);
const counts = computed(() => insights.value?.map((item) => item.count) || []);
const totalRuns = computed(() =>
  counts.value.reduce((sum, current) => sum + current, 0),
);

const chartColors = {
  border: '#E1E3F9',
  point: '#5344E5',
};
</script>

<template>
  <div
    class="border-container mt-m mb-l p-m bg-white w-full gap-3 flex flex-col rounded-2xl items-start"
  >
    <AppCalendar v-model="dateRange" type="daterange" />
    <div class="border border-gray-200 bg-white rounded-xl h-fit p-4 w-full">
      <div class="dimmed font-bold">Total Runs</div>
      <div class="main-title mb-2">
        {{ totalRuns }}
      </div>
      <client-only>
        <LineChart
          v-if="!pending"
          :labels="dates"
          :data="counts"
          label="Spell Runs"
          :border-color="chartColors.border"
          :point-background-color="chartColors.point"
          title="Spell Runs Over Time"
          :class="short ? 'h-40' : 'h-auto'"
        />
        <USkeleton v-else class="h-40" />
      </client-only>
    </div>
  </div>
</template>
